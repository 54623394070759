import Button from '/components/Button';
import Icon from '/components/IconUpdated';
import ProductFacets from '/components/collections/ProductFacets';
import ProductSorter from '/components/collections/ProductSorter';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

const Drawer = dynamic(() => import('/components/Drawer'));
const DrawerBody = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerBody));
const DrawerHeader = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerHeader));
const DrawerFooter = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerFooter));

export default function FilterPanel({
	closeCallback,
	facetGroups = [],
	isOpen,
	onFacetChange,
	onFacetClear,
	onSortChange,
	selectedFacetCount = 0,
	sort,
}) {
	return (
		<Drawer
			isOpen={isOpen}
			position="left"
			toggle={() => {
				closeCallback();
			}}>
			<DrawerHeader>
				<div className="w-full p-2">
					<button className="block p-2 ml-auto" onClick={closeCallback}>
						<Icon name="close" height="18px" width="18px" />
					</button>
				</div>
			</DrawerHeader>
			<DrawerBody>
				{onSortChange && <ProductSorter sort={sort} onChange={onSortChange} />}
				<ProductFacets
					facetGroups={facetGroups}
					onFacetChange={onFacetChange}
					onFacetClear={onFacetClear}
					showSelectedFacetPills={false}
				/>
			</DrawerBody>
			<DrawerFooter>
				<div className="flex justify-between p-2 w-full">
					<Button
						full
						onClick={() => {
							onFacetClear();
						}}
						variant="naked">
						{selectedFacetCount !== 0 ? `Clear ${selectedFacetCount}` : 'Clear'}
					</Button>
					<Button
						full
						onClick={() => {
							closeCallback();
						}}>
						Apply
					</Button>
				</div>
			</DrawerFooter>
		</Drawer>
	);
}

FilterPanel.propTypes = {
	closeCallback: PropTypes.func.isRequired,
	facetGroups: PropTypes.array,
	isOpen: PropTypes.bool.isRequired,
	onFacetChange: PropTypes.func.isRequired,
	onFacetClear: PropTypes.func,
	onSortChange: PropTypes.func,
	selectedFacetCount: PropTypes.number,
	sort: PropTypes.string,
};
