import Badge from '/components/Badge';
import Button from '/components/Button';
import CheckboxGroup from '/components/CheckBoxGroup';
import Divider from '/components/Divider';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { getSelectedFacets } from '/utils/product-facets';
import { titleCase } from '/utils/format';

import { Accordion, AccordionItem, AccordionPanel } from '/components/Accordion';
// import { add, ensure, remove } from '/utils/uniq-array';
import { useEffect, useState } from 'react';

export default function ProductFacets({
	facetGroups = [],
	onFacetChange,
	onFacetClear,
	showSelectedFacetPills = true,
}) {
	const [selectedFacets, setSelectedFacets] = useState([]);

	useEffect(() => {
		const selections = getSelectedFacets(facetGroups);
		setSelectedFacets(selections.facets);
	}, [facetGroups]);

	return (
		<div>
			{showSelectedFacetPills && selectedFacets.length > 0 && (
				<div>
					{/* TODO: typography */}
					<div className="flex justify-between mt-2">
						<Typography className="font-bold px-2 tracking-widest whitespace-nowrap">
							Selected Filters
						</Typography>
						<Button
							className="capitalize !p-0 !text-secondary-dark underline underline-offset-2 whitespace-nowrap"
							variant="naked"
							onClick={() => {
								onFacetClear();
							}}>
							Clear All
						</Button>
					</div>
					<Divider />
					<div className="flex flex-wrap gap-2">
						{selectedFacets.map((facet, i) => {
							return (
								<Badge
									className="whitespace-nowrap mr-1 capitalize"
									color="light"
									key={i}
									onClose={() => onFacetChange(facet.key, facet.title, false)}
									text={`${facet.title}`}
								/>
							);
						})}
					</div>
				</div>
			)}
			<Accordion>
				<form action="#" aria-label="filters form">
					{facetGroups.map((facetGroup, i) => {
						if (facetGroup.facets?.length > 1) {
							return (
								<div key={i}>
									<AccordionItem toggle={facetGroup.key} className="bg-gray-600 text-white">
										<Typography className="whitespace-nowrap" variant="subtitle-sm">
											{facetGroup.title}
										</Typography>
									</AccordionItem>
									<AccordionPanel id={facetGroup.key}>
										{facetGroup.facets.map((facet, i) => {
											return (
												<div key={i} className="px-3">
													<CheckboxGroup
														checked={facet.isSelected}
														disabled={facet.isDisabled}
														name={`${facetGroup.key}-${facet.title}`}
														label={
															<Typography component="p" variant="body">
																{titleCase(facet.title)}
															</Typography>
														}
														onChange={(checked) =>
															onFacetChange(facetGroup.key, facet.title, checked)
														}
													/>
												</div>
											);
										})}
									</AccordionPanel>
								</div>
							);
						}
						return;
					})}
				</form>
			</Accordion>
		</div>
	);
}

ProductFacets.propTypes = {
	collection: PropTypes.string,
	facetGroups: PropTypes.array,
	onFacetChange: PropTypes.func,
	onFacetClear: PropTypes.func,
	showSelectedFacetPills: PropTypes.bool,
};
