export const SORT_OPTIONS = {
	'most relevant': { field: 'relevance', direction: 'desc' },
	'price high to low': { field: 'ss_price', direction: 'desc' },
	'price low to high': { field: 'ss_price', direction: 'asc' },
	newest: { field: 'created_at', direction: 'desc' },
	'best selling': { field: 'sales_rank', direction: 'desc' },
};

export const FACETS = {
	tags: 'tags',
	'_availableSizes.ecommerce': 'Size',
	shopifyProductType: 'Product Type',
};

export const TAG_BASED_FACETS = {
	theme: 'Theme',
	color: 'Color',
	print: 'Print',
	gender: 'Gender',
};
