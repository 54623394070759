import Divider from '/components/Divider';
import PropTypes from 'prop-types';
import RadioGroup from '/components/RadioGroup';
import { SORT_OPTIONS } from '/services/searchspring/constants';
import Typography from '/components/Typography';

export default function ProductSorter({ sort, onChange }) {
	const options = Object.keys(SORT_OPTIONS).reduce((arr, key) => {
		const obj = {
			value: key,
			label: (
				<Typography className="capitalize" component="p" variant="body">
					{key}
				</Typography>
			),
			checked: sort === key,
		};
		arr.push(obj);
		return arr;
	}, []);

	return (
		<>
			<Typography
				className="m-4 flex items-center whitespace-nowrap"
				component="div"
				variant="subtitle-sm">
				Sort By
			</Typography>
			<Divider />
			<div className="ml-4">
				<RadioGroup
					checkedValue={sort}
					name="product_sorter"
					radios={options}
					onChange={onChange}
				/>
			</div>
		</>
	);
}

ProductSorter.propTypes = {
	onChange: PropTypes.func.isRequired,
	sort: PropTypes.string,
};
